import React, { useEffect, useState, useContext } from "react";
import {
  IUseTable,
  RiverDataGrid,
  TableFetchFunction,
  useRiverSelectColumn,
  useTable,
} from "../shared";
import { RiverDialog, useNotification } from "@river/common-ui";
import { IAttachmentHierarchyNode, IWorkPackage } from "@river/interfaces";

import { Column, ValueFormatter } from "react-data-grid";
import { AttachmentDownload } from "./attachment-download";
import { RiverSpinner } from "@river/common-ui";
import styles from "./attachment-hierarchy.module.scss";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../context";
import { useTranslation } from "@river/common-ui";
import { getBlankEntity, useTableCellRenderers } from "../../hooks";
import dataGridStyles from "../../components/shared/river-data-grid/river-data-grid.module.scss";

export interface IWoAttachmentHierarchyProps {
  ids: string[];
  onClose: () => void;
}

export const AttachmentHierarchy: React.FC<IWoAttachmentHierarchyProps> = (
  props
) => {
  const notify = useNotification();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const { t } = useTranslation();
  const { renderCell } = useTableCellRenderers();
  const { RiverSelectColumn } = useRiverSelectColumn();

  const [attachmentDialogOpened, setAttachmentDialogOpened] =
    useState<boolean>(true);
  const [showStatus, setShowStatus] = useState<boolean>(false);
  const [workPackage, setWorkPackage] = useState<IWorkPackage | null>(null);

  const fetchAttachmentHierarchy: TableFetchFunction<
    IAttachmentHierarchyNode
  > = async () => {
    let data: IAttachmentHierarchyNode[] = [];
    try {
      data = await adapterContext!.service
        .getAdapterService()
        .getWoAttachmentHierarchy(props.ids);
    } catch (message) {
      notify.error({ message });
    }

    return data;
  };

  const onDownload = async () => {
    const selectedAttachmentNodes: IAttachmentHierarchyNode[] = (
      table.entities as unknown as IAttachmentHierarchyNode[]
    ).filter((h) => h.is_attachment && table.selectedRowIds.has(h.node_id));

    try {
      table.forceLoadingState(true);
      const workPackage: IWorkPackage = await adapterContext!.service
        .getAdapterService()
        .convertAttachmentsToPdf(selectedAttachmentNodes);
      setWorkPackage(workPackage);
      setAttachmentDialogOpened(false);
      setShowStatus(true);
    } catch (message) {
      notify.error({ message });
    } finally {
      table.forceLoadingState(false);
    }
  };

  const downloadAttachment = async (
    attachmentHierarchyNode: IAttachmentHierarchyNode
  ) => {
    const download = async () => {
      try {
        table.forceLoadingState(true);
        await adapterContext!.service
          .getAdapterService()
          .downloadAttachmentContent(attachmentHierarchyNode);
      } finally {
        table.forceLoadingState(false);
      }
    };

    function isURL(text: string): boolean {
      const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
      return urlRegex.test(text);
    }

    if (isURL(attachmentHierarchyNode.description!)) {
      window.open(attachmentHierarchyNode.description, "_blank");
    } else download();
  };

  const onWorkPackageComplete = async () => {
    setShowStatus(false);
    props.onClose();
  };

  const columns: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "object_id_ext",
      name: t("shared.attachment_hierarchy:attachment_entity.object_id_ext"),
      width: 340,
      formatter: (formatterProps) => {
        const node = formatterProps.row as IAttachmentHierarchyNode;
        const hierLevel = node.hierarchy_level
          ? parseInt(node.hierarchy_level)
          : 1;
        return (
          <>
            {renderCell({
              formatterProps,
              content: (
                <span style={{ paddingLeft: (hierLevel - 1) * 30 }}>
                  {formatterProps.row[formatterProps.column.key]}
                </span>
              ),
            })}
          </>
        );
      },
    },
    {
      key: "attachment_type",
      name: t("shared.attachment_hierarchy:attachment_entity.attachment_type"),
    },
    {
      key: "description",
      name: t("common.label:description"),
      width: 260,
      formatter: (formatterProps) => {
        const node = formatterProps.row as IAttachmentHierarchyNode;
        if (node.is_attachment) {
          return (
            <>
              {renderCell({
                formatterProps,
                content: (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    onClick={() => downloadAttachment(node)}
                    className={dataGridStyles.dataGridLink}
                  >
                    <ValueFormatter {...formatterProps} />
                  </a>
                ),
              })}
            </>
          );
        }
        return (
          <>
            {renderCell({
              formatterProps,
              content: <ValueFormatter {...formatterProps} />,
            })}
          </>
        );
      },
    },
    {
      key: "object_type",
      name: t("shared.attachment_hierarchy:attachment_entity.object_type"),
      width: 160,
    },
  ];

  const table: IUseTable = useTable({
    columns,
    fetchFunction: fetchAttachmentHierarchy,
    fetchOn: true,
  });

  useEffect(() => {
    const defaultSelectedColumns: Set<string> = new Set();
    table.entities.forEach((item) => {
      if (item.is_selected) {
        defaultSelectedColumns.add(item.node_id as string);
      }
    });
    table.setSelectedRowIds(defaultSelectedColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.entities]);

  const entity = getBlankEntity();

  return (
    <>
      <RiverDialog
        title={t("shared.attachment_hierarchy:dialog.attachments.title")}
        onClose={props.onClose}
        open={attachmentDialogOpened}
        closeOnBackdropClick={false}
        showActionsDivider={false}
        actionButtonText={t("common.button:download")}
        onSubmit={onDownload}
        classes={{
          content: styles.content,
          paper: styles.paper,
        }}
        dialogProps={{
          maxWidth: false,
        }}
      >
        <TableContext.Provider value={{ table, entity }}>
          <RiverSpinner show={table.isLoading} />
          <RiverDataGrid
            className={styles.dataGrid}
            columns={table.columns}
            rows={table.entities}
            defaultColumnOptions={{
              sortable: false,
              resizable: true,
            }}
            rowKeyGetter={(row) => row.node_id}
          />
        </TableContext.Provider>
      </RiverDialog>
      {showStatus && (
        <AttachmentDownload
          workPackage={workPackage!}
          onComplete={onWorkPackageComplete}
        />
      )}
    </>
  );
};
