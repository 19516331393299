import React, {
  ReactElement,
  useContext,
  useRef,
  useState,
  useEffect,
  useMemo,
} from "react";
import { Constants } from "@river/constants";
import {
  RiverDialog,
  RiverDialogActionButton,
  useNotification,
  useTranslation,
} from "@river/common-ui";
import {
  IAdapterAsyncAction,
  IAdapterAsyncActionProgressMessage,
} from "@river/interfaces";
import { AdapterUiContext, IAdapterUiContextState } from "../../../../context";
import { AdapterService } from "../../../../services";
import { AsyncProgressSpinner } from "./async-progress-spinner";
import { RiverProgressBar } from "../../river-progress-bar";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ProgressIcon } from "../../../../icons";
import styles from "./river-async-progress.module.scss";
import clsx from "clsx";

const DEFAULT_PENDING_STATUS_TEXT: string =
  "shared.river_async_progress:status_pending.default_label";
const DEFAULT_ACTION_IN_PROGRESS_TEXT: string =
  "shared.river_async_progress:action_in_progress.default_label";
const ACTION_REFRESH_INTERVAL: number = 1000;

enum AsyncProgressView {
  PROGRESS = "progress",
  LOG = "log",
}

const ACTION_STATUS_PENDING: string = Constants.async_action_status.pending;
const ACTION_STATUS_RUNNING: string = Constants.async_action_status.running;
const ACTION_STATUS_CANCELED: string = Constants.async_action_status.cancelled;
const ACTION_STATUS_ERROR: string = Constants.async_action_status.error;
const ACTION_STATUS_SUCCESS: string = Constants.async_action_status.success;

const PROGRESS_TYPE_NONE: string = Constants.async_action_progress_type.none;
const PROGRESS_TYPE_PROCESSED_RECORDS: string =
  Constants.async_action_progress_type.processed_records;
const PROGRESS_TYPE_PERCENTAGE: string =
  Constants.async_action_progress_type.percentage;
const PROGRESS_TYPE_PROCESSED_OF_TOTAL_RECORDS: string =
  Constants.async_action_progress_type.processed_of_total_records;

const MESSAGE_TYPE_INFO: string =
  Constants.async_action_progress_message_type.info;
const MESSAGE_TYPE_WARNING: string =
  Constants.async_action_progress_message_type.warning;
const MESSAGE_TYPE_ERROR: string =
  Constants.async_action_progress_message_type.error;

interface ISummaryInfo {
  name: string;
  className: string;
  messages: IAdapterAsyncActionProgressMessage[];
}
interface ISummaryItem extends ISummaryInfo {
  messageType: string;
}

type ActionSummary = { [messageType: string]: ISummaryItem };

export interface IRiverAsyncProgressProps {
  title: string;
  action: IAdapterAsyncAction;
  onClose: () => void;
  onSuccess?: (action: IAdapterAsyncAction) => void;
  onError?: (action: IAdapterAsyncAction) => void;
  onComplete?: (action: IAdapterAsyncAction) => void;
  pendingStatusText?: string;
  actionInProgressText?: string;
}

const progressTypeClassMapping: { [progressType: string]: string } = {
  [PROGRESS_TYPE_NONE]: styles.progressTypeNone,
  [PROGRESS_TYPE_PERCENTAGE]: styles.progressTypePercentage,
  [PROGRESS_TYPE_PROCESSED_RECORDS]: styles.progressTypeProcessedRecords,
  [PROGRESS_TYPE_PROCESSED_OF_TOTAL_RECORDS]:
    styles.progressTypeProcessedOfTotal,
};

export const RiverAsyncProgress: React.FC<IRiverAsyncProgressProps> = (
  props: IRiverAsyncProgressProps
): ReactElement => {
  const { t } = useTranslation();

  const viewCssClass: { [view in AsyncProgressView]: string } = {
    [AsyncProgressView.LOG]: styles.logView,
    [AsyncProgressView.PROGRESS]: styles.progressView,
  };

  const summaryMap: { [id: string]: ISummaryInfo } = {
    [MESSAGE_TYPE_INFO]: {
      name: t("common.label:info"),
      className: styles.info,
      messages: [],
    },
    [MESSAGE_TYPE_WARNING]: {
      name: t("common.label:warning"),
      className: styles.warning,
      messages: [],
    },
    [MESSAGE_TYPE_ERROR]: {
      name: t("common.label:error"),
      className: styles.error,
      messages: [],
    },
  };
  const initialSummary: ActionSummary = Object.keys(summaryMap).reduce(
    (itemsMap: ActionSummary, key: string) => {
      itemsMap[key] = {
        ...summaryMap[key],
        messageType: key,
      };
      return itemsMap;
    },
    {}
  );

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapter: AdapterService = adapterContext!.service.getAdapterService();
  const [action, setAction] = useState<IAdapterAsyncAction>(props.action);
  const [dialogOpened, setDialogOpened] = useState<boolean>(true);
  const [view, setView] = useState<AsyncProgressView>(
    AsyncProgressView.PROGRESS
  );
  const [summary, setSummary] = useState<ActionSummary>(initialSummary);

  const isActionCompleted = (action: IAdapterAsyncAction): boolean => {
    return (
      [
        ACTION_STATUS_SUCCESS,
        ACTION_STATUS_ERROR,
        ACTION_STATUS_CANCELED,
      ].indexOf(action.status) !== -1
    );
  };

  const isProgressTypeNone: boolean =
    action.progress_type === PROGRESS_TYPE_NONE;
  const isProgressTypePercentage: boolean =
    action.progress_type === PROGRESS_TYPE_PERCENTAGE;
  const isProgressTypeProcessedRecords: boolean =
    action.progress_type === PROGRESS_TYPE_PROCESSED_RECORDS;
  const isProgressTypeProcessedOfTotal: boolean =
    action.progress_type === PROGRESS_TYPE_PROCESSED_OF_TOTAL_RECORDS;

  const isPending: boolean = action.status === ACTION_STATUS_PENDING;
  const isRunning: boolean = action.status === ACTION_STATUS_RUNNING;
  const isCompleted: boolean = isActionCompleted(action);
  const hasProgressMessages: boolean = !!action.progress_messages?.length;
  const isProgressSummaryReady: boolean =
    !isPending && !isProgressTypeNone && !!action.progress_messages?.length;
  const isLogViewReady: boolean = isCompleted && hasProgressMessages;

  const intervalRef = useRef<number>();
  const notify = useNotification();

  const processActionComplete = (newAction: IAdapterAsyncAction): void => {
    const { status, status_description } = newAction;
    if (status === ACTION_STATUS_ERROR) {
      notify.error(status_description || "Undefined Async Status");
      props.onError?.(newAction);
    } else if (status === ACTION_STATUS_SUCCESS) {
      props.onSuccess?.(newAction);
    }
    props.onComplete?.(newAction);
  };

  const processProgressMessages = (action: IAdapterAsyncAction): void => {
    const messages: IAdapterAsyncActionProgressMessage[] =
      action.progress_messages || [];
    if (!messages.length) return;

    const messageTypes: string[] = [
      MESSAGE_TYPE_INFO,
      MESSAGE_TYPE_WARNING,
      MESSAGE_TYPE_ERROR,
    ];
    const results: {
      [messageType: string]: IAdapterAsyncActionProgressMessage[];
    } = {};
    messageTypes.forEach((messageType) => (results[messageType] = []));
    messages.forEach((message) => results[message.type].push(message));

    messageTypes.forEach(
      (messageType) => (summary[messageType].messages = results[messageType])
    );
    const newSummary: ActionSummary = { ...summary };
    setSummary(newSummary);
  };

  const processAction = (newAction: IAdapterAsyncAction): void => {
    // console.log(`processing action ${JSON.stringify(newAction)}`);
    setAction(newAction);
    processProgressMessages(newAction);
    if (isActionCompleted(newAction)) {
      // console.log(`clearing interval for action ${newAction._id}`);
      window.clearInterval(intervalRef.current);
      processActionComplete(newAction);
      if (!newAction.progress_messages?.length) {
        closeDialog();
      }
    }
  };

  // For testing purposes only
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const mockAction = (a: IAdapterAsyncAction) => {
    a.status = ACTION_STATUS_SUCCESS;
    // a.status = ACTION_STATUS_SUCCESS;
    // a.progress_type = PROGRESS_TYPE_NONE;
    a.progress_type = PROGRESS_TYPE_PROCESSED_OF_TOTAL_RECORDS;
    a.progress_messages = [
      { type: "info", message: "Info message 1" },
      { type: "info", message: "Info message 2" },
      { type: "info", message: "Info message 3" },
      { type: "warning", message: "Warning message 1" },
      { type: "warning", message: "Warning message 2" },
      { type: "warning", message: "Warning message 3" },
      { type: "error", message: "Error message 1" },
      { type: "error", message: "Error message 2" },
      { type: "error", message: "Error message 3" },
    ];
    a.progress = 85;
    a.total_records = 478;
  };

  const refreshActionStatus = async (): Promise<void> => {
    try {
      const newAction: IAdapterAsyncAction = await adapter.getAsyncActionStatus(
        props.action._id
      );
      // mockAction(newAction); // For testing purposes
      processAction(newAction);
    } catch (message) {
      notify.error({ message });
      closeDialog();
    }
  };

  useEffect(() => {
    // console.log(`starting interval for action ${action._id}`);
    intervalRef.current = window.setInterval(() => {
      refreshActionStatus();
    }, ACTION_REFRESH_INTERVAL);
    return () => {
      // console.log(`clearing interval for action ${action._id}`);
      window.clearInterval(intervalRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeDialog = (): void => {
    // console.log(`clearing interval for action ${newAction._id}`);
    window.clearInterval(intervalRef.current);
    setDialogOpened(false);
    props.onClose();
  };

  const onViewLog = (): void => {
    setView(AsyncProgressView.LOG);
  };

  const onViewProgress = (): void => {
    setView(AsyncProgressView.PROGRESS);
  };

  const renderViewToggle = (): ReactElement => {
    const renderViewLogButton = () => (
      <Button
        className={styles.viewToggleButton}
        onClick={onViewLog}
        variant={"text"}
      >
        {t("shared.river_async_progress:button.view_log")}
      </Button>
    );
    const renderViewProgressButton = () => (
      <Button
        className={styles.viewToggleButton}
        onClick={onViewProgress}
        variant={"text"}
      >
        {t("shared.river_async_progress:button.view_progress")}
      </Button>
    );
    return (
      <>
        {view === AsyncProgressView.PROGRESS
          ? renderViewLogButton()
          : renderViewProgressButton()}
      </>
    );
  };

  const renderPendingAction = (): ReactElement => {
    const pendingStatusText: string = t(
      props.pendingStatusText || DEFAULT_PENDING_STATUS_TEXT
    );
    return (
      <div className={styles.pendingAction}>
        <AsyncProgressSpinner text={t(pendingStatusText)} />
      </div>
    );
  };

  const renderProgressTypeNone = (): ReactElement => {
    const actionInProgressText: string = t(
      props.actionInProgressText || DEFAULT_ACTION_IN_PROGRESS_TEXT
    );
    return <AsyncProgressSpinner text={t(actionInProgressText)} />;
  };

  const renderProgressTypeProcessedRecords = (): ReactElement => {
    const numRecordsText: string = t(
      "shared.river_async_progress:num_records",
      {
        processed_num: action.total_records,
      }
    );
    return (
      <AsyncProgressSpinner
        muteSpinner={isCompleted}
        text={
          <div className={styles.progressBarText}>
            <span
              className={clsx([styles.processedLabel, processedLabelCssClass])}
            >
              {t("shared.river_async_progress:processed")}
            </span>
            <span className={styles.processedText}>{numRecordsText}</span>
          </div>
        }
      />
    );
  };

  const renderProgressTypeProcessedOfTotal = (): ReactElement => {
    const { progress, total_records } = action;
    const numOfTotalRecordsText: string = t(
      "shared.river_async_progress:num_of_total_records",
      {
        processed_num: progress,
        total_records,
      }
    );
    return (
      <RiverProgressBar
        processed={progress}
        total={total_records}
        text={
          <div className={styles.progressBarText}>
            <span
              className={clsx([styles.processedLabel, processedLabelCssClass])}
            >
              {t("shared.river_async_progress:processed")}
            </span>
            <span className={styles.processedText}>
              {numOfTotalRecordsText}
            </span>
          </div>
        }
      />
    );
  };

  const renderProgressTypePercentage = (): ReactElement => {
    const { progress } = action;
    return (
      <RiverProgressBar
        percentage={action.progress}
        text={
          <div className={styles.progressBarText}>
            <span
              className={clsx([styles.processedLabel, processedLabelCssClass])}
            >
              {t("shared.river_async_progress:processed")}
            </span>
            <span className={styles.processedText}>{progress}%</span>
          </div>
        }
      />
    );
  };

  const renderRunningAction = (): ReactElement => (
    <div
      className={clsx([
        styles.runningAction,
        progressTypeClassMapping[action.progress_type],
      ])}
    >
      {isProgressTypeNone && renderProgressTypeNone()}
      {isProgressTypeProcessedRecords && renderProgressTypeProcessedRecords()}
      {isProgressTypePercentage && renderProgressTypePercentage()}
      {isProgressTypeProcessedOfTotal && renderProgressTypeProcessedOfTotal()}
    </div>
  );

  const renderAction = (): ReactElement => (
    <div className={styles.actionContainer}>
      {isPending && renderPendingAction()}
      {(isRunning || isCompleted) && renderRunningAction()}
    </div>
  );

  const renderProgressSummary = (): ReactElement => {
    const typesToRender: string[] = [
      MESSAGE_TYPE_INFO,
      MESSAGE_TYPE_WARNING,
      MESSAGE_TYPE_ERROR,
    ];
    return (
      <div className={styles.progressSummary}>
        {typesToRender.map((messageType) =>
          renderProgressSummaryItem(summary[messageType])
        )}
      </div>
    );
  };

  const renderProgressSummaryItem = (item: ISummaryItem): ReactElement => {
    const { name, className: itemClass, messages } = item;
    const num: number = messages.length;
    return (
      <>
        <div className={clsx([styles.name, itemClass])}>{name}</div>
        <div className={styles.value}>{num}</div>
        <div /> {/* grid column placeholder */}
      </>
    );
  };

  const renderProgressView = (): ReactElement => (
    <div className={styles.progressView}>
      {renderAction()}
      {isProgressSummaryReady && renderProgressSummary()}
    </div>
  );

  const renderLogSummaryItem = (item: ISummaryItem): ReactElement => {
    const { messageType, name, className } = item;
    const num_messages: number = summary[messageType].messages.length;
    return (
      <>
        <div className={clsx([styles.logSummaryItem, className])}>
          <div className={styles.name}>{name}</div>
          <div className={styles.text}>
            {t("shared.river_async_progress:num_messages", { num_messages })}
          </div>
        </div>
      </>
    );
  };

  const renderSummaryItemMessages = (
    messages: IAdapterAsyncActionProgressMessage[]
  ): ReactElement => (
    <div className={styles.summaryItemMessages}>
      {messages.map((message) => (
        <div className={styles.message}>{message.message}</div>
      ))}
    </div>
  );

  const renderLogView = (): ReactElement => {
    const typesToRender: string[] = [
      MESSAGE_TYPE_INFO,
      MESSAGE_TYPE_WARNING,
      MESSAGE_TYPE_ERROR,
    ];
    return (
      <div className={styles.logView}>
        {typesToRender.map((messageType) => {
          const summaryItem: ISummaryItem = summary[messageType];
          const messages = summaryItem.messages;
          return (
            <>
              {!!messages.length && (
                <div
                  className={clsx([styles.summaryGroup, summaryItem.className])}
                >
                  <Accordion className={styles.accordionRoot}>
                    <AccordionSummary
                      expandIcon={<ArrowRightIcon />}
                      className={styles.accordionSummary}
                    >
                      {renderLogSummaryItem(summaryItem)}
                    </AccordionSummary>
                    <AccordionDetails className={styles.accordionDetails}>
                      {renderSummaryItemMessages(messages)}
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
            </>
          );
        })}
      </div>
    );
  };

  const render = (): ReactElement => (
    <div className={styles.content}>
      {view === AsyncProgressView.PROGRESS
        ? renderProgressView()
        : renderLogView()}
    </div>
  );

  const header: ReactElement = useMemo(
    (): ReactElement => {
      const title: string =
        view === AsyncProgressView.PROGRESS
          ? props.title
          : t("shared.river_async_progress:log_view");

      return (
        <div className={styles.header}>
          <div className={styles.iconSection}>
            <ProgressIcon className={styles.icon} />
          </div>
          <div className={styles.title}>
            <span className={styles.title}>{title}</span>
          </div>
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [view]
  );

  const processedLabelCssClass: string = useMemo(() => {
    let className: string = "";
    const infoSummary: ISummaryItem = summary[MESSAGE_TYPE_INFO];
    const warningSummary: ISummaryItem = summary[MESSAGE_TYPE_WARNING];
    const errorSummary: ISummaryItem = summary[MESSAGE_TYPE_ERROR];

    const hasInfoMessages: boolean = !!infoSummary.messages.length;
    const hasWarningMessages: boolean = !!warningSummary.messages.length;
    const hasErrorMessages: boolean = !!errorSummary.messages.length;

    if (hasErrorMessages) {
      className = errorSummary.className;
    } else if (hasWarningMessages) {
      className = warningSummary.className;
    } else if (hasInfoMessages) {
      className = infoSummary.className;
    }
    return className;
  }, [summary]);

  return (
    <RiverDialog
      open={dialogOpened}
      titleContent={header}
      showTitleDivider={true}
      showActionsDivider={isProgressSummaryReady}
      onClose={closeDialog}
      showXClose={false}
      classes={{
        paper: clsx([styles.paper, viewCssClass[view]]),
        title: styles.dialogTitle,
        content: styles.dialogContent,
      }}
      actionsContent={
        <>
          {isLogViewReady && renderViewToggle()}
          <RiverDialogActionButton
            onClick={closeDialog}
            text={t("common.label:close")}
            className={styles.closeButton}
          />
        </>
      }
    >
      {render()}
    </RiverDialog>
  );
};
