import { useState, useEffect, useContext } from "react";
import { AttributeDataType, IFilterDefinition } from "../interfaces";
import {
  IAttribute,
  IEntityDefinition,
  TQueryOperator,
} from "@river/interfaces";
import { AdapterUiContext, IAdapterUiContextState } from "../context";

export interface UseEntityProps {
  entityName: string;
  definition?: IEntityDefinition;
}

export function useEntity(props: UseEntityProps) {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const [loadingEntity, setLoadingEntity] = useState<boolean>(false);
  const [filterDefinitions, setFilterDefinitions] = useState<
    IFilterDefinition[]
  >([]);
  const [filterDefinitionByAttributeName, setFilterDefinitionByAttributeName] =
    useState<Map<string, IFilterDefinition>>(new Map());
  const [attributesByName, setAttributesByName] = useState<
    Map<string, IAttribute>
  >(new Map());
  const [entityDefinition, setEntityDefinition] = useState<IEntityDefinition>();

  useEffect(() => {
    (async () => {
      setLoadingEntity(true);
      let definition: IEntityDefinition | undefined = props.definition;
      if (!definition) {
        definition = await adapterContext!.service
          .getAdapterService()
          .getEntityInfo(props.entityName);
      }
      setEntityDefinition(definition);
      const attributes: IAttribute[] = definition.attributes;
      const attributesByName: Map<string, IAttribute> = new Map();
      const filterDefinitions: IFilterDefinition[] = [];
      const filterDefinitionByAttributeName: Map<string, IFilterDefinition> =
        new Map();

      const DEFAULT_AVAILABLE_OPERATORS: TQueryOperator[] = ["$eq", "$ne"];

      const getAvailableOperatorsByDataType = (
        dataType: string
      ): TQueryOperator[] => {
        let availableOperators: TQueryOperator[];
        switch (dataType as AttributeDataType) {
          case AttributeDataType.BOOLEAN:
            availableOperators = ["$eq", "$ne"];
            break;
          case AttributeDataType.STRING:
            availableOperators = ["$eq", "$ne", "$regex", "$in", "$nin"];
            break;
          case AttributeDataType.DATE:
          case AttributeDataType.DATETIME:
            availableOperators = ["$eq", "$lt", "$lte", "$gt", "$gte", "$ne"];
            break;
          case AttributeDataType.DOUBLE:
            availableOperators = [
              "$eq",
              "$lt",
              "$lte",
              "$gt",
              "$gte",
              "$ne",
              "$in",
              "$nin",
            ];
            break;
          default: {
            availableOperators = DEFAULT_AVAILABLE_OPERATORS;
          }
        }
        return availableOperators;
      };

      const getDefaultOperatorByDataType = (
        dataType: string
      ): TQueryOperator => {
        let defaultOperator: TQueryOperator;
        switch (dataType as AttributeDataType) {
          case AttributeDataType.STRING:
            defaultOperator = "$regex";
            break;
          default: {
            defaultOperator = "$eq";
            break;
          }
        }
        return defaultOperator;
      };

      const getAttributeDataType = (
        attribute: IAttribute
      ): AttributeDataType => {
        const actualType: AttributeDataType =
          attribute.data_type as AttributeDataType;
        let typeToUse: AttributeDataType;
        const filterableAttributeDataTypes: AttributeDataType[] = [
          AttributeDataType.BOOLEAN,
          AttributeDataType.STRING,
          AttributeDataType.DATE,
          AttributeDataType.DATETIME,
          AttributeDataType.DOUBLE,
        ];
        if (filterableAttributeDataTypes.includes(actualType)) {
          typeToUse = actualType;
        } else {
          typeToUse = AttributeDataType.STRING;
        }
        return typeToUse;
      };

      // -----------------
      const adjustAttributes = (attributes: IAttribute[]): void => {
        for (let attribute of attributes) {
          const attributeName = attribute.attribute_name;
          attributesByName.set(attributeName, attribute);

          if (!isAttributeFilterable(attribute)) {
            continue;
          }

          const dataType: AttributeDataType = getAttributeDataType(attribute);
          const filterDefinition: IFilterDefinition = {
            field: attributeName,
            label:
              attribute.description === attributeName
                ? `${attribute.description}`
                : `${attribute.description} (${attributeName})`,
            operators: getAvailableOperatorsByDataType(dataType),
            defaultOperator: getDefaultOperatorByDataType(dataType),
            dataType,
          };
          filterDefinitions.push(filterDefinition);
          filterDefinitionByAttributeName.set(attributeName, filterDefinition);
        }
      };

      adjustAttributes(attributes);

      setAttributesByName(attributesByName);
      setFilterDefinitions(filterDefinitions);
      setFilterDefinitionByAttributeName(filterDefinitionByAttributeName);
      setLoadingEntity(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.entityName]);

  const isAttributeFilterable = (attribute: IAttribute): boolean => {
    let isFilterable = true;
    if (!attribute.is_persistent) {
      isFilterable = false;
    }
    return isFilterable;
  };

  return {
    entityDefinition,
    attributesByName,
    filterDefinitions,
    filterDefinitionByAttributeName,
    loadingEntity,
  };
}

export type IUseEntity = ReturnType<typeof useEntity>;

export const getBlankEntity = (): IUseEntity => {
  return {
    loadingEntity: false,
    entityDefinition: {
      entity: {
        _id: "",
        adapter_type: "",
        entity_name: "",
      },
      attributes: [],
      relations: [],
      indexes: [],
    },
    attributesByName: new Map<string, IAttribute>(),
    filterDefinitions: [],
    filterDefinitionByAttributeName: new Map<string, IFilterDefinition>(),
  };
};
