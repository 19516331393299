import { TFunction } from "i18next";
import { Column } from "react-data-grid";

import {
  AvailabilityUiService,
  IRenderCreateResourcesDialogProps,
} from "../../availability-ui.service";
import { GenericAvatarIcon } from "../../../icons";

import availabilityStyles from "../../../components/availability/availability.module.scss";
import clsx from "clsx";
import { useTableCellRenderers } from "../../../hooks";
import { JdeAvailabilityCreateResourceDialog } from "./jde-availability-create-resource-dialog/jde-availability-create-resource-dialog";

export class JdeAvailabilityUiService extends AvailabilityUiService {
  getAvailabilityResourcesColumns = (t: TFunction): Column<any>[] => {
    const { renderCell, renderCellText } = useTableCellRenderers();
    return [
      {
        key: "F0101_ALPH",
        name: t("entity.availability_header:availability_header.F0101_ALPH"),
        width: 190,
        formatter: (formatterProps) => (
          <>
            {renderCell({
              formatterProps,
              content: (
                <div className={clsx([availabilityStyles.personCell])}>
                  <GenericAvatarIcon />
                  <div>{renderCellText({ formatterProps })}</div>
                </div>
              ),
            })}
          </>
        ),
      },
      {
        key: "F0101_AN8",
        name: t("entity.availability_header:availability_header.F0101_AN8"),
        width: 200,
      },
      {
        key: "WorkCenters.F30006_MCU",
        name: t("entity.availability_header:workcenter_ref.F30006_MCU"),
        width: 200,
      },
      {
        key: "crews.crew",
        name: t("entity.availability_header:availability_header.crews"),
        width: 200,
      },
      {
        key: "calendar",
        name: t("entity.availability_header:availability_header.calendar"),
        width: 120,
      },
      {
        key: "calendar_start_date",
        name: t(
          "entity.availability_header:availability_header.calendar_start_date"
        ),
        width: 140,
      },
      {
        key: "calendar_end_date",
        name: t(
          "entity.availability_header:availability_header.calendar_end_date"
        ),
        width: 140,
      },
    ];
  };

  getErpSpecificI18nNamespaces(): string[] {
    return [
      "entity.company",
      "shared.crafts",
      "entity.external_resource",
      "entity.address_book",
    ];
  }
  getEditAvailabilityHeaderKey(): string {
    return "F0101_AN8";
  }

  renderCreateResourcesDialog(props: IRenderCreateResourcesDialogProps) {
    return <JdeAvailabilityCreateResourceDialog {...props} />;
  }
}
