import React, { ReactElement, useContext, useState } from "react";
import { GenericAction, IUseTable } from "../../../components/shared";
import PrintIcon from "@mui/icons-material/Print";
import { AttachmentHierarchy } from "../../../components/attachments/attachment-hierarchy";
import { useTranslation } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { useGridActionHelpers } from "../../../helpers";
import { TableUiService } from "../../table-ui.service";

export const useDownloadAttachmentsAction = () => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const tableContext = useContext(TableContext);
  const table: IUseTable = tableContext?.table!;

  const { withSanitizedProps } = useGridActionHelpers();
  const getSelectedRowsObjectIds = tableUiService.getSelectedRowsObjectIds();
  const [dialogOpened, setDialogOpened] = useState<boolean>(false);

  const title: string = t("module.schedule:action_label.download_attachments");

  const onClick = (): void => setDialogOpened(true);

  const renderIcon = (props?: any): ReactElement => (
    <GenericAction
      icon={PrintIcon}
      enableOnSelection={true}
      title={title}
      onClick={onClick}
      {...props}
    />
  );

  const renderDialog = (): ReactElement => (
    <>
      {dialogOpened && (
        <AttachmentHierarchy
          ids={getSelectedRowsObjectIds(table)}
          onClose={() => {
            table.setSelectedRowIds(new Set([]));
            setDialogOpened(false);
          }}
        />
      )}
    </>
  );

  const renderDownloadAttachmentsAction = (): ReactElement => (
    <>
      {renderIcon()}
      {renderDialog()}
    </>
  );

  return {
    renderDownloadAttachmentsAction,
    downloadAttachmentsAction: {
      icon: withSanitizedProps(renderIcon),
      renderDialog,
      title,
      onClick,
    },
  };
};

export type IUseDownloadAttachmentsActions = ReturnType<
  typeof useDownloadAttachmentsAction
>;
