import {
  AdapterBaselineDto,
  AdapterCalendarDto,
  AdapterCrewCraftDto,
  AdapterCrewDto,
  AdapterCrewPersonDto,
  AdapterFolderDto,
  AdapterLookupDto,
  AdapterReportCreateDto,
  AdapterRuleDto,
  AdapterShiftDto,
  AdapterTimeCardDto,
  AdapterTimelineDto,
  AdapterUserStatusActionDto,
  AdapterUserStatusEditActionDto,
  IAdapterAsyncAction,
  IAdapterCalendar,
  IAdapterCrew,
  IAdapterFolder,
  IAdapterJobPriorityScoreAction,
  IAdapterJobValidationAction,
  IAdapterLookup,
  IAdapterReport,
  IAdapterRule,
  IAdapterShift,
  IAdapterTimeCard,
  IAdapterTimeline,
  IAdapterUserStatusActionResponse,
  IEntityObject,
  QueryAttributeDto,
  QueryDto,
  UtilizationPeriodType,
} from "@river/interfaces";
import { httpService } from "@river/common-ui";
import { AdapterUserContextProp, IUserContextSite } from "../../context";
import { AdapterService } from "../adapter.service";
import { Constants } from "@river/constants";

export class JdeAdapterService extends AdapterService {
  // ---------------
  // constructor(
  //   customerInfo: ICustomerInfo,
  //   userProperties: IAdapterUserContextProperties
  // ) {
  //   super(customerInfo, userProperties);
  // }

  // --------------
  protected ensureCompanyQueryAttribute(
    query?: QueryDto,
    companyAttrName: string = "COMPANY"
  ): QueryDto {
    //
    query = this.ensureQueryAttributes(
      [
        {
          attribute_name: companyAttrName,
          attribute_value: {
            operator: "$eq",
            value:
              (
                this.userContext!.userProperties[
                  AdapterUserContextProp.SITE
                ] as IUserContextSite
              )?.site_id || "",
          },
        },
      ],
      query
    );

    return query;
  }

  // --------------
  async fetchSites(query?: QueryDto | undefined): Promise<any[]> {
    return this.searchEntityData("company", query);
  }

  // --------------
  async fetchTimelines(
    query?: QueryDto | undefined
  ): Promise<IAdapterTimeline[]> {
    query = this.ensureCompanyQueryAttribute(query);
    return await this.searchEntityData("timeline", query);
  }

  // --------------
  async createTimeline(
    timelineCreateParams: AdapterTimelineDto
  ): Promise<IAdapterTimeline> {
    //
    const timelineDto: AdapterTimelineDto = {
      ...timelineCreateParams,
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.post<IAdapterTimeline>(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/timeline`,
      timelineDto
    );
    return response.data;
  }

  // --------------
  async updateTimeline(
    timelineId: string,
    timelineUpdateParams: AdapterTimelineDto
  ): Promise<IAdapterTimeline> {
    const timelineDto: AdapterTimelineDto = {
      ...timelineUpdateParams,
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.put<IAdapterTimeline>(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/timeline/${timelineId}`,
      timelineDto
    );
    return response.data;
  }

  // --------------
  async fetchFolders(query?: QueryDto | undefined): Promise<IAdapterFolder[]> {
    query = this.ensureCompanyQueryAttribute(query);
    if (!query.sort?.length) {
      query.sort = [
        {
          attribute_name: "start_date",
          sort: "asc",
        },
      ];
    }
    return await this.searchEntityData("folder", query);
  }

  // --------------
  async createFolder(
    folderCreateParams: AdapterFolderDto
  ): Promise<IAdapterFolder> {
    const folderDto: AdapterFolderDto = {
      ...folderCreateParams,
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.post<IAdapterFolder>(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/folder`,
      folderDto
    );
    return response.data;
  }

  // --------------
  async updateFolder(
    folderId: string,
    folderUpdateParams: AdapterFolderDto
  ): Promise<IAdapterFolder> {
    const folderDto: AdapterFolderDto = {
      ...folderUpdateParams,
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.put<IAdapterFolder>(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/folder/${folderId}`,
      folderDto
    );
    return response.data;
  }

  // --------------
  async fetchShifts(query?: QueryDto | undefined): Promise<IAdapterShift[]> {
    query = this.ensureCompanyQueryAttribute(query);
    return await this.searchEntityData("shift", query);
  }

  // --------------
  async createShift(shift: AdapterShiftDto): Promise<IAdapterShift> {
    const shiftDto: AdapterShiftDto = {
      ...shift,
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/shift`,
      shiftDto
    );
    return response.data;
  }

  // --------------
  async updateShift(
    shiftId: string,
    shift: AdapterShiftDto
  ): Promise<IAdapterShift> {
    const shiftDto: AdapterShiftDto = {
      ...shift,
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/shift/${shiftId}`,
      shiftDto
    );
    return response.data;
  }

  // ==========================
  // Crews
  // ==========================
  async fetchCrews(
    query?: QueryDto | undefined,
    user_id?: string
  ): Promise<IAdapterCrew[]> {
    if (user_id) {
      query = this.ensureQueryAttributes(
        [
          {
            attribute_name: "supervisors",
            attribute_value: {
              operator: "$elemMatch",
              value: { $eq: user_id },
            },
          },
        ],
        query
      );
    }
    query = this.ensureCompanyQueryAttribute(query);
    return await this.searchEntityData("crew", query);
  }

  // -------------------
  // available (not assigned) people
  async fetchCrewAvailablePeople(
    crewCode: string,
    query?: QueryDto
  ): Promise<any[]> {
    //
    const company = (
      this.userContext!.userProperties[
        AdapterUserContextProp.SITE
      ] as IUserContextSite
    ).site_id;

    query = this.ensureQueryAttributes(
      [
        {
          attribute_name: "crews",
          attribute_value: {
            operator: "$not",
            value: {
              $elemMatch: { crew: crewCode, COMPANY: company },
            },
          },
        },
      ],
      query
    );

    query = this.ensureCompanyQueryAttribute(query);

    return await this.searchEntityData("availability_header", query);
  }

  // -------------------
  // assigned people
  async fetchCrewAssignedPeople(
    crewCode: string,
    query?: QueryDto
  ): Promise<any[]> {
    //
    query = this.ensureQueryAttributes(
      [
        {
          attribute_name: "crew",
          attribute_value: {
            operator: "$eq",
            value: crewCode,
          },
        },
      ],
      query
    );

    query = this.ensureCompanyQueryAttribute(query);
    return await this.searchEntityData("crew_person", query);
  }

  // --------------
  async createCrew(crew: AdapterCrewDto): Promise<IAdapterCrew> {
    const crewDto: AdapterCrewDto = {
      ...crew,
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/crew`,
      crewDto
    );
    return response.data;
  }

  // --------------
  async updateCrew(
    crewId: string,
    crew: AdapterCrewDto
  ): Promise<IAdapterCrew> {
    const crewDto: AdapterCrewDto = {
      ...crew,
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/crew/${crewId}`,
      crewDto
    );
    return response.data;
  }

  // -----------------
  // Create a person
  async createCrewPerson(crewPerson: AdapterCrewPersonDto): Promise<any> {
    //
    const crewPersonDto = {
      ...crewPerson,
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };

    return await this.createEntityData("crew_person", crewPersonDto);
  }

  // -------------
  // assign a craft to a crew
  async createCrewCraft(crewCraft: AdapterCrewCraftDto): Promise<any> {
    //
    const crewCraftDto = {
      ...crewCraft,
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };

    return await this.createEntityData("crew_workcenter", crewCraftDto);
  }

  // ------------
  async updateCrewCraft(
    crewCraftId: string,
    crewCraft: AdapterCrewCraftDto
  ): Promise<any> {
    //
    const crewCraftDto = {
      ...crewCraft,
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };

    return await this.updateEntityData(
      "crew_workcenter",
      crewCraftId,
      crewCraftDto
    );
  }

  // -----------
  async deleteCrewCraft(id: string): Promise<any> {
    //
    return this.deleteEntityData("crew_workcenter", id);
  }

  // --------------
  async fetchCalendars(
    query?: QueryDto | undefined
  ): Promise<IAdapterCalendar[]> {
    query = this.ensureCompanyQueryAttribute(query);
    return await this.searchEntityData("calendar", query);
  }

  // --------------
  async createCalendar(
    calendar: AdapterCalendarDto
  ): Promise<IAdapterCalendar> {
    const calendarDto: AdapterCalendarDto = {
      ...calendar,
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/calendar`,
      calendarDto
    );
    return response.data;
  }

  // --------------
  async updateCalendar(
    calendarId: string,
    calendar: AdapterCalendarDto
  ): Promise<IAdapterCalendar> {
    const calendarDto: AdapterCalendarDto = {
      ...calendar,
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };

    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/calendar/${calendarId}`,
      calendarDto
    );
    return response.data;
  }

  // --------------
  async fetchExternalResources(
    query?: QueryDto | undefined
  ): Promise<IEntityObject[]> {
    query = this.ensureCompanyQueryAttribute(query);
    return await this.searchEntityData("external_resource", query);
  }

  // --------------
  async createExternalResource(
    resource: IEntityObject
  ): Promise<IEntityObject> {
    const resourceDto: IEntityObject = {
      ...resource,
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/external_resource`,
      resourceDto
    );
    return response.data;
  }

  // --------------
  // async updateExternalResource(
  //   resourceId: string,
  //   resource: IEntityObject
  // ): Promise<IEntityObject> {
  //   const resourceDto: IEntityObject = {
  //     ...resource,
  //     PlanningPlant: (
  //       this.userContext!.properties[
  //         AdapterUserContextProp.SITE
  //       ] as IUserContextSite
  //     ).site_id,
  //   };
  //   const response = await httpService.put(
  //     `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/external_resource/${resourceId}`,
  //     resourceDto
  //   );
  //   return response.data;
  // }

  // --------------
  // async fetchLabor(query?: QueryDto | undefined): Promise<any[]> {
  //   query = this.ensurePlanningPlantQueryAttribute(query, "PlanningPlant");
  //   return await this.searchEntityData("workcenterlabor", query);
  // }

  // --------------
  async createAvailabilityResource(
    resource: IEntityObject
  ): Promise<IEntityObject> {
    //
    const resourceDto = {
      ...resource,
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };

    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/availability_header`,
      resourceDto
    );
    return response.data;
  }

  // -------------
  async deleteAvailabilityResource(resourceId: string): Promise<IEntityObject> {
    return await this.deleteEntityData("availability_header", resourceId);
  }

  // --------------
  async fetchAvailability(
    startDate: Date,
    query?: QueryDto | undefined,
    crew?: string
  ): Promise<any[]> {
    //
    const queryAttributes: QueryAttributeDto[] = [
      {
        attribute_name: "start_date",
        attribute_value: {
          operator: "$eq",
          value: startDate.toISOString().substr(0, 10),
        },
      },
    ];

    if (crew) {
      queryAttributes.push({
        attribute_name: "crews",
        attribute_value: {
          operator: "$elemMatch",
          value: { crew: { $eq: crew } },
        },
      });
    }

    query = this.ensureQueryAttributes(queryAttributes, query);
    query = this.ensureCompanyQueryAttribute(query);

    return await this.searchEntityData("availability_header", query);
  }

  // ----------------
  // TODO... not sure we can do this as we cannot sync the whole JDE address book
  async synchronizeResources(): Promise<IAdapterAsyncAction> {
    const query = this.ensureCompanyQueryAttribute(new QueryDto());
    const { data } = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/sync-resources`,
      query
    );

    return data;
  }

  // ----------------
  async synchronizeWorkorders(): Promise<void> {
    //
    const query = this.ensureCompanyQueryAttribute(new QueryDto());
    await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/sync-workorders`,
      query
    );
  }

  // --------------
  async fetchWorkorders(query?: QueryDto | undefined): Promise<any[]> {
    query = this.ensureCompanyQueryAttribute(query);
    const response = await httpService.post<IEntityObject[]>(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/workorder/search`,
      query
    );
    return response.data;
  }

  // --------------
  async fetchOperations(query?: QueryDto | undefined): Promise<any[]> {
    query = this.ensureCompanyQueryAttribute(query);
    const response = await httpService.post<IEntityObject[]>(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/operation/search`,
      query
    );
    return response.data;
  }

  // --------------
  async fetchAssignments(query?: QueryDto | undefined): Promise<any[]> {
    query = this.ensureCompanyQueryAttribute(query);

    const unwind = !query.$unwind
      ? []
      : Array.isArray(query.$unwind)
        ? query.$unwind
        : [query.$unwind];
    unwind.push("assignments");

    query.$unwind = unwind;
    const response = await httpService.post<IEntityObject[]>(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/operation/search`,
      query
    );
    return response.data;
  }

  // --------------
  async fetchCrafts(query?: QueryDto | undefined): Promise<any[]> {
    //query = this.ensureBusinessUnitQueryAttribute(query, "PlanningPlant");
    return await this.searchEntityData("workcenter", query);
  }

  // --------------
  async fetchCraftPeople(query?: QueryDto | undefined): Promise<any[]> {
    query = this.ensureCompanyQueryAttribute(query);
    return await this.searchEntityData("availability_header", query);
  }

  // --------------
  async fetchCraftUtilization(
    folderId: string,
    baselineId: string | null,
    startDate: Date,
    periodType: UtilizationPeriodType,
    periods: number,
    query?: QueryDto | undefined
  ): Promise<any[]> {
    // "query" parameter should contain an array of "WorkCenterId" values:
    // {
    //   attributes: [{
    //     attribute_name: "WorkCenterId",
    //     values: [{
    //       operator: '$in',
    //       value: ["10000118", "10004076"]
    //     }]
    //   }]
    // }

    query = this.ensureQueryAttributes(
      [
        {
          attribute_name: "folder_id",
          attribute_value: {
            operator: "$eq",
            value: folderId,
          },
        },
        {
          attribute_name: "baseline_id",
          attribute_value: {
            operator: "$eq",
            value: baselineId,
          },
        },
        {
          attribute_name: "start_date",
          attribute_value: {
            operator: "$eq",
            value: startDate,
          },
        },
        {
          attribute_name: "period_type",
          attribute_value: {
            operator: "$eq",
            value: periodType,
          },
        },
        {
          attribute_name: "periods",
          attribute_value: {
            operator: "$eq",
            value: periods,
          },
        },
      ],
      query
    );

    //query = this.ensurePlanningPlantQueryAttribute(query, "PlanningPlant");

    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/workcenter_utilization/search`,
      query || {}
    );
    return response.data;
  }

  // --------------
  async fetchResourceUtilization(
    folderId: string,
    baselineId: string | null,
    startDate: Date,
    periodType: UtilizationPeriodType,
    periods: number,
    query?: QueryDto | undefined
  ): Promise<any[]> {
    // "query" parameter should contain an array of "PersonNumber" values:
    // {
    //   attributes: [{
    //     attribute_name: "PersonNumber",
    //     values: [{
    //       operator: '$in',
    //       value: ["00001811", "00001911"]
    //     }]
    //   }]
    // }

    query = this.ensureQueryAttributes(
      [
        {
          attribute_name: "folder_id",
          attribute_value: {
            operator: "$eq",
            value: folderId,
          },
        },
        {
          attribute_name: "baseline_id",
          attribute_value: {
            operator: "$eq",
            value: baselineId,
          },
        },
        {
          attribute_name: "start_date",
          attribute_value: {
            operator: "$eq",
            value: startDate,
          },
        },
        {
          attribute_name: "period_type",
          attribute_value: {
            operator: "$eq",
            value: periodType,
          },
        },
        {
          attribute_name: "periods",
          attribute_value: {
            operator: "$eq",
            value: periods,
          },
        },
      ],
      query
    );

    // Intentionaly commented out

    // query = this.ensurePlanningPlantQueryAttribute(
    //   query,
    //   "WorkCenters.PlanningPlant"
    // );

    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/person_utilization/search`,
      query || {}
    );
    return response.data;
  }

  // --------------
  // TODO
  async fetchMaterial(
    workorderNumbers: string[],
    query?: QueryDto | undefined
  ): Promise<any[]> {
    query = this.ensureQueryAttributes(
      [
        {
          attribute_name: "F3111_DOCO",
          attribute_value: {
            operator: "$in",
            value: workorderNumbers,
          },
        },
      ],
      query
    );

    //const response = await httpService.post<IEntityObject[]>(`${this.getAdapterSvcBaseUrl()}/api/adapter/entities/workorder/search`, query);
    const response = await httpService.post<IEntityObject[]>(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/wo_part/search`,
      query
    );
    return response.data;
  }

  // --------------
  async fetchCrewUtilization(
    folderId: string,
    baselineId: string | null,
    startDate: Date,
    periodType: UtilizationPeriodType,
    periods: number,
    query?: QueryDto | undefined
  ): Promise<any[]> {
    // "query" parameter should contain an array of "crew" values:
    // {
    //   attributes: [{
    //     attribute_name: "crew",
    //     values: [{
    //       operator: '$in',
    //       value: ["Crew1", "Crew2"]
    //     }]
    //   }]
    // }

    query = this.ensureQueryAttributes(
      [
        {
          attribute_name: "folder_id",
          attribute_value: {
            operator: "$eq",
            value: folderId,
          },
        },
        {
          attribute_name: "baseline_id",
          attribute_value: {
            operator: "$eq",
            value: baselineId,
          },
        },
        {
          attribute_name: "start_date",
          attribute_value: {
            operator: "$eq",
            value: startDate,
          },
        },
        {
          attribute_name: "period_type",
          attribute_value: {
            operator: "$eq",
            value: periodType,
          },
        },
        {
          attribute_name: "periods",
          attribute_value: {
            operator: "$eq",
            value: periods,
          },
        },
      ],
      query
    );

    // intentionally commented out
    //query = this.ensureOrganizationIdAttribute(query);

    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/crew_utilization/search`,
      query || {}
    );
    return response.data;
  }

  // --------------
  async fetchRules(
    ruleType: string | string[],
    query?: QueryDto | undefined
  ): Promise<IAdapterRule[]> {
    //
    query = this.ensureQueryAttributes(
      [
        {
          attribute_name: "rule_type",
          attribute_value: {
            operator: "$in",
            value: Array.isArray(ruleType) ? ruleType : [ruleType],
          },
        },
      ],
      query
    );

    query = this.ensureCompanyQueryAttribute(query);
    return await this.searchEntityData("rule", query);
  }

  // --------------
  async fetchFolderRules(
    folderId: string,
    query?: QueryDto | undefined
  ): Promise<IAdapterRule[]> {
    //
    query = this.ensureQueryAttributes(
      [
        {
          attribute_name: "rule_type",
          attribute_value: {
            operator: "$eq",
            value: Constants.rule_type.validation_rule,
          },
        },
        {
          attribute_name: "folder_id",
          attribute_value: {
            operator: "$eq",
            value: folderId,
          },
        },
      ],
      query
    );

    query = this.ensureCompanyQueryAttribute(query);
    return await this.searchEntityData("rule", query);
  }

  // --------------
  async createRule(rule: AdapterRuleDto): Promise<IAdapterRule> {
    const ruleDto: AdapterRuleDto = {
      ...rule,
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/rule`,
      ruleDto
    );
    return response.data;
  }

  // --------------
  async updateRule(
    ruleId: string,
    rule: AdapterRuleDto
  ): Promise<IAdapterRule> {
    const ruleDto: AdapterRuleDto = {
      ...rule,
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/rule/${ruleId}`,
      ruleDto
    );
    return response.data;
  }

  // --------------
  async jobPriorityScore(workorderIds: string[]): Promise<void> {
    //
    const jobPriorityScoreAction: IAdapterJobPriorityScoreAction = {
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
      entity_name: "workorder",
      entity_ids: workorderIds,
    };

    await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/job-priority-score`,
      jobPriorityScoreAction
    );
  }

  // --------------
  async jobValidationFilter(
    ruleId: string,
    folderId?: string | undefined
  ): Promise<any[]> {
    //
    const jobPriorityScoreAction: IAdapterJobValidationAction = {
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
      entity_name: "workorder",
      rule_id: ruleId,
      folder_id: folderId,
    };

    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/job-validation-filter`,
      jobPriorityScoreAction
    );
    return response.data;
  }

  // --------------
  async jobValidationFilterCount(
    ruleId: string,
    folderId?: string | undefined
  ): Promise<number> {
    //
    const jobPriorityScoreAction: IAdapterJobValidationAction = {
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
      entity_name: "workorder",
      rule_id: ruleId,
      folder_id: folderId,
    };

    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/job-validation-filter-count`,
      jobPriorityScoreAction
    );
    return response.data;
  }

  // --------------
  async jobValidationAction(
    entityName: string,
    entityIds: string[],
    ruleId: string,
    actionId: string,
    folderId?: string | undefined
  ): Promise<IAdapterAsyncAction> {
    const jobPriorityScoreAction: IAdapterJobValidationAction = {
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
      entity_name: entityName,
      entity_ids: entityIds,
      rule_id: ruleId,
      action_id: actionId,
      folder_id: folderId,
    };

    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/job-validation-action`,
      jobPriorityScoreAction
    );
    return response.data;
  }

  // --------------
  async createFolderBaseline(baselineDto: AdapterBaselineDto): Promise<void> {
    const payload: AdapterBaselineDto = {
      ...baselineDto,
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };

    await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/baseline`,
      payload
    );
  }

  // --------------
  async fetchFolderBaselines(
    folderId: string,
    query?: QueryDto | undefined
  ): Promise<any[]> {
    //
    query = query = this.ensureQueryAttributes(
      [
        {
          attribute_name: "folder_id",
          attribute_value: {
            operator: "$eq",
            value: folderId,
          },
        },
      ],
      query
    );

    query = this.ensureCompanyQueryAttribute(query);
    return await this.searchEntityData("baseline", query);
  }

  // -------------
  async createTimeCard(
    timecard: AdapterTimeCardDto
  ): Promise<IAdapterTimeCard> {
    const timecardDto: AdapterTimeCardDto = {
      ...timecard,
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/timecard`,
      timecardDto
    );
    return response.data;
  }

  // ------------
  async updateTimeCard(
    timecardId: string,
    timecard: AdapterTimeCardDto
  ): Promise<IAdapterTimeCard> {
    const timecardDto: AdapterTimeCardDto = {
      ...timecard,
      COMPANY: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/timecard/${timecardId}`,
      timecardDto
    );
    return response.data;
  }

  // --------------
  async openRecordInErp(record: any, entityType: string): Promise<void> {
    // helper function to determine the clinet OS type
    // const isWindows = (): boolean => {
    //   return window.navigator?.userAgent?.includes("Windows");
    // };

    // determine the client operatin system - if Windows - prepare the shortcut file for Windows SAP GUI,
    // otherwise prepare a shortcut file for Java SAP GUI.

    // TODO - the example below is for SAP GUI for Java
    // TODO - Also need to support SAP GUI for Web GUI
    const jdeWebGuiUrlProperty = (
      this.customerInfo.environment.options || []
    ).find((p) => p.name === "jde-webgui-url");

    switch (entityType) {
      //
      case "workorder":
        let workorderUrl = `${jdeWebGuiUrlProperty?.value}/jde/ShortcutLauncher?OID=P13700_W13700B_ZJDE0001&FormDSTmpl=|1|&FormDSData=|${record.F4801_DOCO}|`;
        window.open(workorderUrl);

        break;
    }
  }

  // ===================
  // User Status
  // ===================
  async getUserStatusResponse(
    userStatusAction: AdapterUserStatusActionDto
  ): Promise<IAdapterUserStatusActionResponse> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/user-status`,
      userStatusAction
    );
    return response.data;
  }

  // ------------
  async updateUserStatus(
    userStatusEditAction: AdapterUserStatusEditActionDto
  ): Promise<void> {
    await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/user-status-edit`,
      userStatusEditAction
    );
  }

  // ==================
  // Lookups
  // ==================
  // async fetchPlannerGroups(query?: QueryDto): Promise<any[]> {
  //   query = this.ensureBusinessUnitQueryAttribute(query);
  //   return await this.searchEntityData("planner_group", query);
  // }

  // // ------------
  // async fetchRevisions(query?: QueryDto): Promise<any[]> {
  //   query = this.ensureBusinessUnitQueryAttribute(query);
  //   return await this.searchEntityData("revision", query);
  // }

  // // ------------
  // async fetchPmActivityTypes(query?: QueryDto): Promise<any[]> {
  //   return await this.searchEntityData("pm_activity_type", query);
  // }

  // // ------------
  // async fetchSystemConditions(query?: QueryDto): Promise<any[]> {
  //   return await this.searchEntityData("system_condition", query);
  // }

  // // --------------
  // async fetchEquipment(query?: QueryDto): Promise<any[]> {
  //   query = this.ensureBusinessUnitQueryAttribute(query, "PlanningPlant");
  //   const response = await httpService.post(
  //     `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/equipment/search`,
  //     query
  //   );
  //   return response.data;
  // }

  // // --------------
  // async fetchWbsElements(query?: QueryDto): Promise<any[]> {
  //   //query = this.ensurePlanningPlantQueryAttribute(query, "PlanningPlant");
  //   const response = await httpService.post(
  //     `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/wbs/search`,
  //     query
  //   );
  //   return response.data;
  // }

  // // --------------
  // async fetchNotifications(query?: QueryDto): Promise<any[]> {
  //   query = this.ensureBusinessUnitQueryAttribute(query);

  //   const response = await httpService.post<any[]>(
  //     `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/notification/search`,
  //     query
  //   );
  //   return response.data;
  // }

  // -------------
  // async createNotification(notification: IEntityObject): Promise<any> {
  //   /*
  //   Notification fields:

  //   "NotificationType": string => default value to "M1",
  //   "Equipment": string => lookup of "Equipment" attribute from "equipment" entity
  //   "Description": string => (max 40 characters)

  //   */

  //   const notificationDto: IEntityObject = {
  //     ...notification,
  //     PlanningPlant: (
  //       this.userContext!.userProperties[
  //         AdapterUserContextProp.SITE
  //       ] as IUserContextSite
  //     ).site_id,
  //   };
  //   const response = await httpService.post(
  //     `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/notification`,
  //     notificationDto
  //   );
  //   return response.data;
  // }

  // -------------
  // async updateNotification(notification: IEntityObject): Promise<any> {
  //   const notificationDto: IEntityObject = {
  //     ...notification,
  //     PlanningPlant: (
  //       this.userContext!.userProperties[
  //         AdapterUserContextProp.SITE
  //       ] as IUserContextSite
  //     ).site_id,
  //   };
  //   const response = await httpService.put(
  //     `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/notification`,
  //     notificationDto
  //   );
  //   return response.data;
  // }

  // --------------
  async fetchLookups(
    lookupType: string,
    query?: QueryDto | undefined
  ): Promise<IAdapterLookup[]> {
    //
    query = this.ensureQueryAttributes(
      [
        {
          attribute_name: "lookup_type",
          attribute_value: {
            operator: "$eq",
            value: lookupType,
          },
        },
      ],
      query
    );
    return await this.searchEntityData("lookup", query);
  }

  // --------------
  async createLookup(lookup: AdapterLookupDto): Promise<IAdapterLookup> {
    const lookupDto: AdapterLookupDto = {
      ...lookup,
    };
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/lookup`,
      lookupDto
    );
    return response.data;
  }

  // --------------
  async updateLookup(
    lookupId: string,
    lookup: AdapterLookupDto
  ): Promise<IAdapterLookup> {
    const lookupDto: AdapterLookupDto = {
      ...lookup,
    };
    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/lookup/${lookupId}`,
      lookupDto
    );
    return response.data;
  }

  // --------------
  async getTotalReturnedBreakIns(folderId: string) {
    let query = this.ensureQueryAttributes([
      {
        attribute_name: "folder_id",
        attribute_value: {
          operator: "$eq",
          value: folderId,
        },
      },
    ]);

    return await this.searchEntityData("total_break_ins", query ?? {});
  }

  // --------------
  async getTotalReturnedWorkorders(folderId: string) {
    let query = this.ensureQueryAttributes([
      {
        attribute_name: "folder_id",
        attribute_value: {
          operator: "$eq",
          value: folderId,
        },
      },
    ]);

    return await this.searchEntityData(
      "total_returned_workorders",
      query ?? {}
    );
  }

  // --------------
  async getTotalScheduledHours(folderId: string) {
    let query = this.ensureQueryAttributes([
      {
        attribute_name: "folder_id",
        attribute_value: {
          operator: "$eq",
          value: folderId,
        },
      },
    ]);

    return await this.searchEntityData("total_scheduled_hours", query ?? {});
  }

  // --------------
  async getTotalActualHours(folderId: string) {
    let query = this.ensureQueryAttributes([
      {
        attribute_name: "folder_id",
        attribute_value: {
          operator: "$eq",
          value: folderId,
        },
      },
    ]);
    return await this.searchEntityData("total_actual_hours", query ?? {});
  }

  // --------------
  async getScheduledWorkOrdersCompleted(folderId: string, baselineId?: string) {
    let query = this.ensureQueryAttributes([
      {
        attribute_name: "folder_id",
        attribute_value: {
          operator: "$eq",
          value: folderId,
        },
      },
      {
        attribute_name: "baseline_id",
        attribute_value: {
          operator: "$eq",
          value: baselineId ?? null,
        },
      },
    ]);

    return await this.searchEntityData(
      "scheduled_work_orders_completed",
      query
    );
  }

  async generateReport(
    payload: AdapterReportCreateDto
  ): Promise<IAdapterReport> {
    payload.query = this.ensureCompanyQueryAttribute(payload.query);
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/attachments/report`,
      payload
    );
    return response.data;
  }
}
