import { ReactElement } from "react";
import {
  ITableFetchFunctionProps,
  ILookupDefinition,
} from "../../components/shared";
import { TFunction } from "react-i18next";
import { AdapterUserContextProp, IUserContextSite } from "../../context";
import { fetchHelpers } from "../../helpers";
import {
  CraftsUiService,
  ICraftSelectorProps,
  IUseCraftLookupProps,
} from "../crafts-ui.service";
import { Column } from "react-data-grid";
import { JdeCraftSelector } from "./jde-shared/jde-craft-selector";
import { QueryAttributeGroupDto } from "@river/interfaces";

export const CRAFT_ROW_KEY_SEPARATOR: string = ".SEPARATOR.";

export interface IJdeCraftKey {
  F30006_MCU: string;
  F30006_MMCU: string;
}

export class JdeCraftsUiService extends CraftsUiService {
  getCraftEntity = () => "workcenter";
  craftKeyGetter = (row: any): string =>
    `${row["F30006_MCU"]}${CRAFT_ROW_KEY_SEPARATOR}${row["F30006_MMCU"]}`;

  getSelectedCraftsQuery = (
    selectedCraftIds: string[] | number[]
  ): QueryAttributeGroupDto => {
    const createKeyQuery = (
      stringifiedCraftKey: string
    ): QueryAttributeGroupDto => {
      const key: IJdeCraftKey = JSON.parse(stringifiedCraftKey);
      return {
        $and: [
          {
            attribute_name: "F30006_MCU",
            attribute_value: { operator: "$eq", value: key.F30006_MCU },
          },
          {
            attribute_name: "F30006_MMCU",
            attribute_value: { operator: "$eq", value: key.F30006_MMCU },
          },
        ],
      };
    };

    const selectedCraftsQuery: QueryAttributeGroupDto = {
      $or: selectedCraftIds.map((craftKey) =>
        createKeyQuery(craftKey as string)
      ),
    };

    const emptyResultsQuery: QueryAttributeGroupDto = {
      $and: [
        {
          attribute_name: "RESOURCE_ID",
          attribute_value: { operator: "$eq", value: -1 },
        },
      ],
    };

    return selectedCraftIds.length ? selectedCraftsQuery : emptyResultsQuery;
  };
  getCraftLookupDefinition = (
    props: IUseCraftLookupProps
  ): ILookupDefinition => {
    const site: IUserContextSite =
      props.userContext?.userProperties[AdapterUserContextProp.SITE];

    return {
      title: props.t("shared.lookup:craft.title"),
      rowKeyGetter: this.craftKeyGetter,
      useTableProps: {
        columns: this.getCraftsSelectorColumns(
          props.t,
          props.RiverSelectColumn
        ),
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await props
            .adapterContext!.service.getAdapterService()
            .fetchCrafts(
              fetchHelpers.getTableQuery({
                fetchProps,
                initialQueryAttributes: props.customFilters,
              })
            );
        },
        dependencies: [!!site],
        saveKey: this.getCraftsLookupTableSaveKey(),
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: this.getCraftEntity(),
      },
    };
  };
  getCraftsSelectorColumns = (
    t: TFunction,
    RiverSelectColumn: Column<any, any>
  ) => [
    RiverSelectColumn,
    {
      key: "F30006_MCU",
      name: t("entity.workcenter:workcenter.F30006_MCU"),
      width: 150,
    },
    {
      key: "F30006_MCUW",
      name: t("entity.workcenter:workcenter.F30006_MCUW"),
    },
    { key: "F30006_MMCU", name: t("entity.workcenter:workcenter.F30006_MMCU") },
    {
      key: "F30006_LOCN",
      name: t("entity.workcenter:workcenter.F30006_LOCN"),
    },
  ];

  getAssignedCraftsSelectorColumns = (
    t: TFunction,
    RiverSelectColumn: Column<any, any>
  ) => this.getCraftsSelectorColumns(t, RiverSelectColumn);

  getCraftsUtilizationIds = (selectedCraftIds: string[]): string[] =>
    selectedCraftIds;

  renderCraftsSelector = (props: ICraftSelectorProps): ReactElement => (
    <JdeCraftSelector {...props} />
  );
}
