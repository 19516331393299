import React, { ReactElement, useContext, useEffect } from "react";
import {
  IColumnFilter,
  IFilterDefinition,
  ITableFilter,
} from "../../../interfaces";
import {
  IRiverAutocompleteOption,
  IRiverSimpleSelectItem,
  ISelectOption,
  RiverAutocomplete,
  RiverCheckbox,
  RiverFormContext,
  RiverFormSelect,
  RiverRadioGroup,
  RiverSpinner,
  RiverTextInput,
  useTranslation,
} from "@river/common-ui";
import {
  IQueryAttribute,
  IQueryAttributeGroup,
  TQueryOperator,
} from "@river/interfaces";
import { TableContext } from "../../../context";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import Button from "@mui/material/Button";
import {
  commonModuleActions,
  RiverFormInstance,
  useCurrentModule,
} from "../../../hooks";
import {
  DEFAULT_FILTER_SCOPE,
  IAdvancedFiltersForm,
  TableFilterScope,
  useAdvancedFiltersForm,
} from "./use-advanced-filters-form";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { DeleteIcon } from "../../../icons";
import { IUseTable, gridHelpers } from "../river-data-grid";
import {
  IUseDeleteFilterConfirmation,
  useDeleteFilterConfirmation,
} from "./use-delete-filter-confirmation";
import styles from "./advanced-filters.module.scss";

export enum AttributeGroupOperator {
  AND = "$and",
  OR = "$or",
}

type AttributeGroupElement = IQueryAttribute | IQueryAttributeGroup;

export interface IUseAdvancedFiltersProps {
  filter?: ITableFilter | null;
  refresh?: boolean; // refreshes the form with current props.filter
  scope?: TableFilterScope | null;
  onSave?: () => void;
  onApply?: (filter: ITableFilter) => void;
  onDelete?: () => void;
}

const DEFAULT_FILTER_QUERY: IQueryAttributeGroup = {
  [AttributeGroupOperator.AND]: [],
};

export const getGroupOperator = (
  group: IQueryAttributeGroup
): AttributeGroupOperator => Object.keys(group)[0] as AttributeGroupOperator;

export const getGroupElements = (
  group: IQueryAttributeGroup
): AttributeGroupElement[] => group[getGroupOperator(group)] || [];

export const mergeQueryAttributeGroups = (
  groups: (IQueryAttributeGroup | undefined)[]
): IQueryAttributeGroup => {
  const groupsToMerge: IQueryAttributeGroup[] = groups.filter(
    (group) => !!group
  ) as IQueryAttributeGroup[];

  if (groupsToMerge.length === 1) {
    return groupsToMerge[0];
  } else {
    return {
      $and: groupsToMerge,
    };
  }
};

export function useAdvancedFilters(props: IUseAdvancedFiltersProps) {
  const { t } = useTranslation();
  const currentModule = useCurrentModule();
  const deleteFilterConfirmation: IUseDeleteFilterConfirmation =
    useDeleteFilterConfirmation();

  const isAttributeElement = (element: AttributeGroupElement): boolean =>
    !!element.attribute_name;

  const tableContext = useContext(TableContext);
  const table: IUseTable = tableContext!.table;

  const filterDefinitions: IFilterDefinition[] =
    tableContext?.entity.filterDefinitions || [];

  const getDefaultAttribute = (): IQueryAttribute => ({
    attribute_name: filterDefinitions[0]?.field ?? "",
    attribute_value: {
      value: filterDefinitions[0]?.defaultValue ?? "",
      operator: filterDefinitions[0]?.defaultOperator ?? "$eq",
    },
  });

  const getDefaultAttributeGroup = (): IQueryAttributeGroup => ({
    [AttributeGroupOperator.AND]: [getDefaultAttribute()],
  });

  const getDefaultSimpleFilterAttribute = (): IQueryAttribute => {
    const availableFilters: IFilterDefinition[] =
      getAvailableSimpleFilterDefinitions();

    return {
      attribute_name: availableFilters[0]?.field ?? "",
      attribute_value: {
        value: availableFilters[0]?.defaultValue ?? "",
        operator: availableFilters[0]?.defaultOperator ?? "$eq",
      },
    };
  };

  const form: RiverFormInstance = useAdvancedFiltersForm({
    filter: props.filter,
    scope: props.scope || DEFAULT_FILTER_SCOPE,
    onSave: () => props.onSave?.(),
  });

  const { setStandaloneFields, submit } = form;
  const formFields: IAdvancedFiltersForm =
    form.standalone as IAdvancedFiltersForm;
  const {
    query: rootGroup,
    scope,
    simpleFiltersAttributeGroup: simpleFilterRootGroup,
  } = formFields;

  const getAvailableSimpleFilterDefinitions = (): IFilterDefinition[] => {
    const existingFilters: IQueryAttribute[] = getGroupElements(
      simpleFilterRootGroup
    ) as IQueryAttribute[];
    const columnAttributes = gridHelpers
      .getNonUtilityColumns(table.columns)
      .map((column) => column.attribute);
    const availableFilters: IFilterDefinition[] = filterDefinitions.filter(
      (filterDefinition) =>
        !existingFilters.find(
          (existingFilter) =>
            existingFilter.attribute_name === filterDefinition.field
        )
    );

    // Sort available filters by table column attributes first
    availableFilters.sort((a, b) => {
      if (
        columnAttributes.includes(a.field) &&
        !columnAttributes.includes(b.field)
      ) {
        return -1;
      } else if (
        !columnAttributes.includes(a.field) &&
        columnAttributes.includes(b.field)
      ) {
        return 1;
      } else {
        return 0;
      }
    });

    return availableFilters;
  };

  useEffect(() => {
    if (props.refresh) {
      if (!props.filter) {
        form.resetForm();
      }
      if (props.filter) {
        let newFields = { ...formFields };

        newFields.query = props.filter?.query || DEFAULT_FILTER_QUERY;
        newFields.simpleFiltersAttributeGroup =
          gridHelpers.getSimpleFiltersQueryGroup(
            props.filter?.simpleFilters || []
          );

        Object.setPrototypeOf(newFields, Object.getPrototypeOf(formFields));
        setStandaloneFields(newFields);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  const filterOperatorItems: IRiverSimpleSelectItem[] = [
    {
      value: AttributeGroupOperator.AND,
      text: t("shared.advanced_filters:group_operator_label.and"),
    },
    {
      value: AttributeGroupOperator.OR,
      text: t("shared.advanced_filters:group_operator_label.or"),
    },
  ];

  const operatorOptions: ISelectOption<TQueryOperator>[] = [
    {
      label: t("shared.advanced_filters:operator_label.equals"),
      value: "$eq",
    },
    {
      label: t("shared.advanced_filters:operator_label.not_equals"),
      value: "$ne",
    },
    {
      label: t("shared.advanced_filters:operator_label.greater_than"),
      value: "$gt",
    },
    {
      label: t("shared.advanced_filters:operator_label.greater_than_or_equal"),
      value: "$gte",
    },
    {
      label: t("shared.advanced_filters:operator_label.less_than"),
      value: "$lt",
    },
    {
      label: t("shared.advanced_filters:operator_label.less_than_or_equal"),
      value: "$lte",
    },
    {
      label: t("shared.advanced_filters:operator_label.like"),
      value: "$regex",
    },
    {
      label: t("shared.advanced_filters:operator_label.not_like"),
      value: "$regex_not",
    },
  ];

  const removeFilter = (group: IQueryAttributeGroup, index: number) => {
    getGroupElements(group).splice(index, 1);
    updateFormFields();
  };

  const renderAttribute = (
    group: IQueryAttributeGroup,
    attribute: IQueryAttribute,
    index: number,
    isSimpleFilter?: boolean
  ) => {
    const columnOptions: IRiverAutocompleteOption[] = filterDefinitions.map(
      (availFilter) => ({
        label: availFilter.label,
        value: availFilter.field,
      })
    );

    return (
      <div className={styles.attribute} key={index}>
        <RiverAutocomplete
          id={"field"}
          className={styles.attribute_name}
          label={t("shared.advanced_filters:field_label.column")}
          noOptionsText={t(
            "shared.advanced_filters:label.no_available_columns"
          )}
          value={
            columnOptions.find(
              (option) => option.value === attribute.attribute_name
            )!
          }
          onChange={(event, value) => {
            if (value) {
              attribute.attribute_name = (
                value as IRiverAutocompleteOption
              ).value;

              if (isSimpleFilter) {
                const simpleFilterDefinition: IFilterDefinition | undefined =
                  tableContext?.entity.filterDefinitionByAttributeName.get(
                    attribute.attribute_name
                  );
                attribute.attribute_value.operator =
                  simpleFilterDefinition?.defaultOperator ?? "$eq";
              }
              updateFormFields();
            }
          }}
          options={columnOptions}
        />
        <RiverFormSelect
          label={t("shared.advanced_filters:field_label.operator")}
          id={"operator"}
          value={attribute.attribute_value.operator}
          className={styles.attribute_operator}
          items={operatorOptions.map((operator) => ({
            text: operator.label,
            value: operator.value,
          }))}
          disabled={isSimpleFilter}
          onChangeEvent={(
            event: SelectChangeEvent<{ name?: string; value: unknown }>
          ): void => {
            attribute.attribute_value.operator = event.target
              .value as TQueryOperator;
            updateFormFields();
          }}
        />
        <RiverTextInput
          id={"value"}
          label={t("shared.advanced_filters:field_label.value")}
          value={attribute.attribute_value.value}
          className={styles.attribute_value}
          onChange={(newValue) => {
            attribute.attribute_value.value = newValue;
            updateFormFields();
          }}
        />

        <DeleteIcon
          className={styles.deleteFilterIcon}
          onClick={() => {
            removeFilter(group, index);
          }}
          title={"Delete Filter"}
        />
      </div>
    );
  };

  const updateFormFields = (): void => {
    const newFields = { ...formFields };
    Object.setPrototypeOf(newFields, Object.getPrototypeOf(formFields));
    setStandaloneFields(newFields);
  };

  const addFilter = (
    parentGroup: IQueryAttributeGroup,
    isSimpleFilter?: boolean
  ) => {
    const defaultAttribute: IQueryAttribute = isSimpleFilter
      ? getDefaultSimpleFilterAttribute()
      : getDefaultAttribute();

    getGroupElements(parentGroup).push(defaultAttribute);
    updateFormFields();
  };

  const addFilterGroup = (parentGroup: IQueryAttributeGroup) => {
    const defaultGroup: IQueryAttributeGroup = getDefaultAttributeGroup();
    getGroupElements(parentGroup).push(defaultGroup);
    updateFormFields();
  };

  const clearFilterGroup = (group: IQueryAttributeGroup) => {
    group[getGroupOperator(group)] = [];
    updateFormFields();
  };

  const deleteFilterGroup = (props: {
    parentGroup: IQueryAttributeGroup;
    groupIndex: number;
  }) => {
    const { parentGroup, groupIndex } = props;
    getGroupElements(parentGroup).splice(groupIndex, 1);
    updateFormFields();
  };

  const renderGroupFooter = (props: {
    parentGroup?: IQueryAttributeGroup;
    group: IQueryAttributeGroup;
    groupIndex?: number;
    isSimpleFilter?: boolean;
  }): ReactElement => {
    const { parentGroup, group, groupIndex, isSimpleFilter } = props;
    return (
      <div className={styles.groupFooter}>
        <Button
          variant="text"
          color="primary"
          className={styles.footerButton}
          startIcon={<AddIcon className={styles.icon} />}
          onClick={() => addFilter(group, isSimpleFilter)}
        >
          {t("shared.advanced_filters:add_filter")}
        </Button>
        {!isSimpleFilter && (
          <Button
            variant="text"
            color="primary"
            className={styles.footerButton}
            startIcon={<AddIcon className={styles.icon} />}
            onClick={() => addFilterGroup(group)}
          >
            {t("shared.advanced_filters:add_filter_group")}
          </Button>
        )}

        <Button
          variant="text"
          color="primary"
          className={styles.footerButton}
          startIcon={<RemoveIcon className={styles.icon} />}
          onClick={() => clearFilterGroup(group)}
          disabled={!getGroupElements(group).length}
        >
          {t("shared.advanced_filters:clear_group")}
        </Button>
        {parentGroup && (
          <Button
            variant="text"
            color="primary"
            className={styles.footerButton}
            startIcon={<DeleteIcon className={styles.icon} />}
            onClick={() =>
              deleteFilterGroup({ parentGroup, groupIndex: groupIndex! })
            }
          >
            {t("shared.advanced_filters:delete_group")}
          </Button>
        )}
      </div>
    );
  };

  const renderGroupElements = (props: {
    parentGroup?: IQueryAttributeGroup;
    group: IQueryAttributeGroup;
    isSimpleFilter?: boolean;
  }): ReactElement => {
    const { group, isSimpleFilter } = props;
    return (
      <div className={styles.groupElements}>
        {getGroupElements(group).map((element, elementIndex) => {
          return isAttributeElement(element)
            ? renderAttribute(
                group,
                element as IQueryAttribute,
                elementIndex,
                isSimpleFilter
              )
            : renderAttributeGroup({
                parentGroup: group,
                group: element as IQueryAttributeGroup,
                groupIndex: elementIndex,
              });
        })}
      </div>
    );
  };

  const renderAttributeGroup = (props: {
    parentGroup?: IQueryAttributeGroup;
    group: IQueryAttributeGroup;
    groupIndex?: number;
    isSimpleFilter?: boolean;
  }): ReactElement => {
    const { parentGroup, group, groupIndex, isSimpleFilter } = props;
    const operator: AttributeGroupOperator = getGroupOperator(group);
    if (!operator) return <></>;

    return (
      <div className={styles.attributeGroup} key={groupIndex}>
        <RiverFormSelect
          id={"groupOperator"}
          className={styles.groupOperator}
          items={filterOperatorItems}
          disabled={isSimpleFilter}
          onChangeEvent={(
            event: SelectChangeEvent<{ name?: string; value: unknown }>
          ): void => {
            const currentOperator: AttributeGroupOperator =
              getGroupOperator(group);
            const currentElements: AttributeGroupElement[] =
              getGroupElements(group);
            const newOperator: AttributeGroupOperator = event.target
              .value as AttributeGroupOperator;
            delete group[currentOperator];
            group[newOperator] = currentElements;
            updateFormFields();
          }}
          value={operator}
          selectProps={{
            classes: {
              outlined: styles.outlined,
            },
          }}
        />
        {renderGroupElements({ parentGroup, group, isSimpleFilter })}
        {renderGroupFooter({ parentGroup, group, groupIndex, isSimpleFilter })}
      </div>
    );
  };

  const save = async (): Promise<void> => {
    await submit();
  };

  const apply = async (): Promise<void> => {
    const simpleFiltersAttributes: IQueryAttribute[] = getGroupElements(
      simpleFilterRootGroup
    ) as IQueryAttribute[];
    const simpleFilters: IColumnFilter[] = simpleFiltersAttributes.map(
      (attribute) => ({
        field: attribute.attribute_name,
        operator: attribute.attribute_value.operator,
        value: attribute.attribute_value.value,
      })
    );

    await table.fetch({
      newQuery: mergeQueryAttributeGroups([rootGroup, simpleFilterRootGroup]),
      newColumnFilters: simpleFilters,
    });

    const tableFilter: ITableFilter = {
      name: formFields.name,
      query: rootGroup,
      simpleFilters,
    };

    props.onApply?.(tableFilter);
  };

  const deleteFilter = async (): Promise<void> => {
    deleteFilterConfirmation.open({
      filter: props.filter!,
      scope,
      onDelete: () => props.onDelete?.(),
    });
  };

  const isScopeEditable = (): boolean => {
    const { GlobalFilterAction } = commonModuleActions;
    const canCreateGlobalFilter: boolean = currentModule.isActionAllowed(
      GlobalFilterAction.CREATE_GLOBAL_FILTER
    );
    const canDeleteGlobalFilter: boolean = currentModule.isActionAllowed(
      GlobalFilterAction.DELETE_GLOBAL_FILTER
    );
    const isGlobalFilter: boolean = props.scope === TableFilterScope.GLOBAL;

    const isEditable: boolean =
      (isGlobalFilter && canDeleteGlobalFilter) ||
      (!isGlobalFilter && canCreateGlobalFilter);

    return isEditable;
  };

  const renderMetaSection = (): ReactElement => (
    <RiverFormContext.Provider value={{ form }}>
      <div className={styles.metaSection}>
        <div className={styles.nameSection}>
          <RiverTextInput id={"name"} className={styles.filterName} />
          <RiverCheckbox id={"isDefault"} className={styles.isDefault} />
        </div>
        <RiverRadioGroup
          id={"scope"}
          inline
          classes={{
            groupLabel: styles.label,
          }}
          options={[TableFilterScope.LOCAL, TableFilterScope.GLOBAL]}
          disabled={!isScopeEditable()}
          className={styles.scope}
        />
      </div>
    </RiverFormContext.Provider>
  );

  const renderSimpleFilterSection = (params?: {
    renderSectionTitle?: boolean;
  }): ReactElement => {
    const renderSectionTitle = () => (
      <div className={styles.querySectionTitle}>{t("Simple Filters")}</div>
    );
    return (
      <div className={styles.querySection}>
        {renderSectionTitle()}
        {renderAttributeGroup({
          group: simpleFilterRootGroup,
          isSimpleFilter: true,
        })}
      </div>
    );
  };

  const renderQuerySection = (params?: {
    renderSectionTitle?: boolean;
  }): ReactElement => {
    const renderSectionTitle = () => (
      <div className={styles.querySectionTitle}>{t("Advanced Filters")}</div>
    );
    const renderFilterName = () => (
      <div className={styles.querySectionFilterName}>{props.filter?.name}</div>
    );
    return (
      <div className={styles.querySection}>
        {params?.renderSectionTitle && renderSectionTitle()}
        {!params?.renderSectionTitle && props.filter && renderFilterName()}
        {renderAttributeGroup({ group: rootGroup })}
      </div>
    );
  };

  const render = (): ReactElement => (
    <RiverFormContext.Provider value={{ form }}>
      <div className={styles.root}>
        <RiverSpinner show={form.isProcessing} />
        {renderMetaSection()}
        {renderQuerySection({ renderSectionTitle: true })}
        {deleteFilterConfirmation.render()}
      </div>
    </RiverFormContext.Provider>
  );

  return {
    renderMetaSection,
    renderSimpleFilterSection,
    renderQuerySection,
    render,
    apply,
    delete: deleteFilter,
    save,
    form,
    query: rootGroup,
    simpleFiltersAttributeGroup: simpleFilterRootGroup,
  };
}

export type IUseAdvancedFilters = ReturnType<typeof useAdvancedFilters>;
